const DrawLine = {}

DrawLine.drawLine = (googleMaps, pointA, pointB, identifier, options, markerClickHandler, details, doNotdrawLine) => {
    if (!window.google) throw 'Google Maps Not initialised'
    if (!googleMaps) throw 'Google Maps Reference object required'
    if (!pointA) throw 'Invalid PointA (start position)'
    if (!pointB) throw 'Invalid PointB (end position)'

    console.log('DrawLine Start')

    const newOptions = {
        lineColor: '#b0a378',
        lineOpacity: 1.0,
        lineWeight: 2,
        icon: '/tower.svg',
        ...options,
    }

    const path = [pointA, pointB]
    const linePath = new window.google.maps.Polyline({
        path,
        geodesic: true,
        strokeColor: newOptions.lineColor,
        strokeOpacity: newOptions.lineOpacity,
        strokeWeight: newOptions.lineWeight,
    })

    const marker = new window.google.maps.Marker({
        position: pointB,
        map: googleMaps,
        optimized: false,
        draggable: false,
        icon: {
            url: newOptions.icon,
            scaledSize: new window.google.maps.Size(38, 38),
            size: new window.google.maps.Size(38, 38),
            anchor: new window.google.maps.Point(16, 25),
        },
    })

    if (markerClickHandler) {
        marker.addListener('click', function () {
            markerClickHandler(marker, details)
        })
    }
    if (!doNotdrawLine) linePath.setMap(googleMaps)

    return { identifier, path: linePath, marker }
}

DrawLine.clearLines = (polylines) => {
    if (polylines && polylines.length > 0) {
        for (let i = 0; i < polylines.length; i++) {
            if (polylines[i] && polylines[i].path) {
                polylines[i].path.setMap(null)
                polylines[i].marker.setMap(null)
            }
        }
        polylines = []
    }
    return polylines
}

export default DrawLine
